// Lägg till användare
import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
// import Link from "react-router-dom/Link";
// import { gql, graphql } from 'react-apollo';

class Installningar extends Component {
    
    render() {
        return (
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <h2>
                            Inställningar
                        </h2>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default Installningar;