// Lägg till användare
import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
import Link from "react-router-dom/Link";
import Select from 'react-select';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class AddInsurance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            dotterbolag: [],
            customersData: [],
            dotterbolagData: [],
            customersKoncernId: '',
            dotterbolagId: '',
            customerForsakringsform: '',

            customerForsakringsnummer: '',
            customerProjektforsakring: '',
            customerForsakringsbolag: '',
            customerKundansvarig: '',
            customerUppdragUtgang: new Date(),

            customerOvrigInfo: '',
            customerArvode: '',
            customerRorligt: '',
            customerArvodeFakturerat: '',
            customerFornyelse: new Date(),

            customerPremieKr: '',
            customerProvisionProcent: '',
            customerProvisionKr: '',
            customerSarskildFakturaRutin: '',
            customerAPS1: '',

            customerAPS2AF: '',
            customerAPS2BU: '',
            customerAPS3P: '',
            customerArchived: 0,
            showSuccess: false,
            procentValue: 0,
            customerStartDate: new Date(),

            DotterBolagen: []
        };

        this.addInsurance = this.addInsurance.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleSubCustomerChange = this.handleSubCustomerChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleNewDateChange = this.handleNewDateChange.bind(this);
        this.fetchSubCustomer = this.fetchSubCustomer.bind(this);
    }

    addInsurance(event) {
        event.preventDefault();
        var self = this;

        axios.post(getApiUrl() + '/public/addInsurance', {
            Koncern_id: this.state.customersKoncernId,
            Dotterbolag_id: this.state.dotterbolagId,
            Uppdragsavtalets_utgang: moment(this.state.customerUppdragUtgang).format('YYYY-MM-DD'),
            Ovrig_information: this.state.customerOvrigInfo,
            Arvode: this.state.customerArvode,

            Rorligt: this.state.customerRorligt,
            Arvode_fakturerat: this.state.customerArvodeFakturerat,
            Forsakringsform: this.state.customerForsakringsform,
            Projektforsakring: this.state.customerProjektforsakring,
            Forsakringsnr: this.state.customerForsakringsnummer,

            Fornyelse: moment(this.state.customerFornyelse).format('YYYY-MM-DD'),
            Forsakringsbolag: this.state.customerForsakringsbolag,
            Premie_kr: this.state.customerPremieKr,
            Provision_procent: this.state.customerProvisionProcent,
            Provision_kr: (this.state.customerPremieKr / 100) * this.state.customerProvisionProcent,

            Sarskild_faktura_rutin: this.state.customerSarskildFakturaRutin,
            ARBETSPROCESS_STEG_1: this.state.customerAPS1,
            ARBETSPROCESS_STEG_2_A_FORNYELSE: this.state.customerAPS2AF,
            ARBETSPROCESS_STEG_2_B_UPPHANDLING: this.state.customerAPS2BU,
            ARBETSPROCESS_STEG_3_PLACERING: this.state.customerAPS3P,

            ARBETSPROCESS_STEG_4_ANDRING: this.state.customerAPS4P,
            Arkiverad: this.state.customerArchived
        })
            .then(function () {
                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.getCustomerData();
    }

    getCustomerData() {
        axios.get(getApiUrl() + '/public/getcustomersdata')
            .then(response => JSON.parse(JSON.stringify(response)))
            .then(customersdata => this.setState({ customersData: customersdata.data.customersData }))
            .catch(err => console.error('Error ', err.toString()));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleNewDateChange(date) {
        this.setState({ customerFornyelse: date });
    }
    
    handleEndDateChange(date) {
        this.setState({ customerUppdragUtgang: date });
    }
    
    fetchSubCustomer(customer) {
        axios.get(getApiUrl() + '/public/singleSubCustomer?koncern=' + customer)
        .then(response => JSON.parse(JSON.stringify(response)))
        .then((res) =>{
            this.setState({
                DotterBolagen: res.data.dotterbolagData.map(data => ({
                    Namn: data.Dotterbolag,
                    ID: data.Dotterbolag_id
                }))
            });
        })
    }

    handleCustomerChange(event) {
        axios.get(getApiUrl() + '/public/singleCustomer?koncern=' + event.label)
            .then(response => JSON.parse(JSON.stringify(response)))
            .then(koncerndata => this.setState({ customersKoncernId: koncerndata.data.koncernData.Koncern_id }))
            .then(this.fetchSubCustomer(event.value))
    }

    handleSubCustomerChange(event) {
        this.setState({
            dotterbolagId: event.value
        });
    }

    render() {
        const message = this.state.showSuccess;

        let procentValueStart = 0;
        let procentValue = (this.state.customerPremieKr / 100) * this.state.customerProvisionProcent;

        // funkar detta?
        const options = this.state.customersData.map(customers => ({
            value: customers.Koncern_id,
            label: customers.Koncern
        }));

        const options_subcustomer = this.state.DotterBolagen.map(dotterbolag => ({
            value: dotterbolag.ID,
            label: dotterbolag.Namn
        }));

        return (
            <div className="app-content block">
                <div className="block">
                    <div className="columns content-head">
                        <div className="column">
                            <h2 className="title is-large">
                                Skapa ny försäkring
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="block"></div>
                <form onSubmit={this.addInsurance}>
                    <div className="columns">
                        <div className="column">
                            <h6 className="title is-6">Bolagsinformation</h6>
                        </div>
                        <div className="column is-6">
                            <div>
                                <label className="label">Välj koncern
    
                                <div className="field">
                                        <div className="control">
                                            <span className="select-field">
                                                <Select
                                                    placeholder="Koncern"
                                                    value={this.state.selectedValueCustomer}
                                                    onChange={this.handleCustomerChange}
                                                    options={options}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label className="label">Välj dotterbolag
                                    <div className="field">
                                        <div className="control">
                                            <span className="select-field">
                                                <Select
                                                    placeholder="Dotterbolag"
                                                    value={this.state.selectedValueSubCustomer}
                                                    onChange={this.handleSubCustomerChange}
                                                    options={options_subcustomer}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="column is-3">
                            <label className="label">&nbsp;</label>
                            <button className="right button is-primary is-outlined">
                                <Link
                                    to={{
                                        pathname: `/ny-kund`
                                    }}>
                                    <FontAwesomeIcon icon="plus" /> Lägg till kund
                                </Link>
                            </button>
                        </div>
                    </div>

                    <div className="block"><hr /></div>

                    <div className="columns is-multiline">
                        <div className="column is-3">
                            <h6 className="title is-6">Uppdrag & Avtal</h6>
                        </div>
                        <div className="column is-3">
                            <label className="label">Uppdragsavtalets utgång
                                <div className="field">
                                    <div className="control">
                                        <DatePicker
                                            className="input"
                                            selected={this.state.customerUppdragUtgang}
                                            onChange={this.handleEndDateChange}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3">
                            <label className="label">Arvode
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="number" name="customerArvode" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3">
                            <label className="label">Arvode fakturerat
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="number" name="customerArvodeFakturerat" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3"></div>
                        <div className="column is-3">
                            <label className="label">Rörligt
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="number" name="customerRorligt" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3">
                            <label className="label">Förnyelsedatum
                                <div className="field">
                                    <div className="control">
                                        <DatePicker
                                            className="input"
                                            selected={this.state.customerFornyelse}
                                            onChange={this.handleNewDateChange}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3"></div>
                    </div>

                    <div className="block"><hr /></div>

                    <div className="columns is-multiline">
                        <div className="column is-3">
                            <h6 className="title is-6">Försäkring</h6>
                        </div>
                        <div className="column is-3">
                            <label className="label">Försäkringsform
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="customerForsakringsform" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3">
                            <label className="label">Projektförsäkring
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="customerProjektforsakring" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3"></div>
                        <div className="column is-3"></div>
                        <div className="column is-3">
                            <label className="label">Försäkringsnummer
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="number" name="customerForsakringsnummer" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3">
                            <label className="label">Försäkringsbolag
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="customerForsakringsbolag" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3"></div>
                    </div>

                    <div className="block"><hr /></div>

                    <div className="columns">
                        <div className="column is-3">
                            <label className="label">
                                <h6 className="title is-6">Fakturering m.m.</h6>
                            </label>
                        </div>
                        <div className="column is-3">
                            <label className="label">Premier (kr)
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="number" name="customerPremieKr" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3">
                            <label className="label">Provision %
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="number" name="customerProvisionProcent" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column is-3">
                            <label className="label">Provision kr
                            {/* Percentage */}
                                <p>{procentValue ? procentValue : procentValueStart}</p><br />
                            </label>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column is-3"></div>
                        <div className="column is-3">
                            <label className="label">Särskild fakturarutin
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="customerSarskildFakturaRutin" onChange={this.onChange} />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column">
                            <label className="label">Övrig information
                            <div className="field">
                                    <div className="control">
                                        <textarea className="textarea" name="customerOvrigInfo" placeholder="Övrig information" onChange={this.onChange}></textarea>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="block"><hr /></div>

                    <div className="block">
                        {message && (
                            <article className="message is-success">
                                <div className="message-body">
                                    Försäkringen har skapats!
                                    </div>
                            </article>
                        )}
                        <button className="button is-primary is-outlined left">
                            <Link
                                to={{
                                    pathname: `/forsakringar`,
                                }}>
                                <FontAwesomeIcon icon="caret-left" /> Tillbaka till Försäkringar
                            </Link>
                        </button>
                        <button className="button is-primary is-outlined right" type="submit"><FontAwesomeIcon icon="save" /> Spara försäkring</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default AddInsurance;