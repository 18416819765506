// Lägg till användare
import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import Link from "react-router-dom/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class Insurance extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeInsurance: []
        };

        // this.getInsuranceData = this.getInsuranceData.bind(this);
    }

    componentDidMount() {
        this.getSingleInsurance();
    }

    getSingleInsurance() {
        const { match: { params } } = this.props;

        axios.get(getApiUrl() + '/public/getSingleInsurance?insuranceid=' + params.id)
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(insurancesdata => this.setState({activeInsurance: insurancesdata.data.insuranceData}))
        .catch(err => console.error('Error ', err.toString()));
    }

    render() {
        const insurance = this.state.activeInsurance;
        console.log(insurance);
        
        return (
            <div className="app-content block">
                {this.state.activeInsurance.length !== 0 &&

                <div className="block">
                    <div className="columns content-head">
                        <div className="column">
                            <h2 className="title is-large">
                                {insurance.Koncern}
                            </h2>
                        </div>
                    </div>
                    <div className="block">
                        <div className="columns">
                            <div className="column">
                                <h6  className="title is-6">Bolagsinformation</h6>
                            </div>
                            <div className="column">
                                <p><strong>Koncern:</strong> <br /><span>{insurance.Koncern}</span></p>
                                <p><strong>Organisationsnummer:</strong> <br /><span>{insurance.Organisationsnummer}</span></p>
                            </div>
                            <div className="column">
                                <p><strong>Dotterbolag:</strong> <br /><span>{insurance.Dotterbolag}</span></p>
                                <p><strong>Dotterbolag organisationsnummer:</strong> <br /><span>{insurance.Organisationsnummer_Dotterbolag}</span></p>
                            </div>
                            <div className="column">
                                <p><strong>Övrig information:</strong> <br /><span>{insurance.Ovrig_information}</span></p>
                            </div>
                        </div>

                        <div className="block"><hr/></div>
                        
                        <div className="columns">
                            <div className="column">
                                <h6  className="title is-6">Uppdrag & Avtal</h6>
                            </div>
                            <div className="column">
                                <p><strong>Kundansvarig:</strong> <br /><span>{insurance.Kundansvarig}</span></p>
                                <p><strong>Uppdragsavtalets utgång:</strong> <br /><span>{insurance.Uppdragsavtalets_utgang}</span></p>
                            </div>
                            <div className="column">
                                <p><strong>Arvode:</strong> <br /><span>{insurance.Arvode}</span></p>
                                <p><strong>Rördivgt:</strong> <br /><span>{insurance.Rordivgt}</span></p>
                            </div>

                            <div className="column">
                                <p><strong>Arvode fakturerat:</strong> <br /><span>{insurance.Arvode_fakturerat}</span></p>
                                <p><strong>Förnyelse:</strong> <br /><span>{insurance.Fornyelse}</span></p>
                            </div>
                        </div>
                        
                        <div className="block"><hr/></div>

                        <div className="columns">
                            <div className="column">
                                <h6  className="title is-6">Försäkring</h6>
                            </div>
                            <div className="column">    
                                <p><strong>Försäkringsform:</strong> <br /><span>{insurance.Forsakringsform}</span></p>
                                <p><strong>Projektförsäkring:</strong> <br /><span>{insurance.Projektforsakring}</span></p>
                            </div>
                            <div className="column">
                                <p><strong>Försäkringsnummer:</strong> <br /><span>{insurance.Forsakringsnr}</span></p>
                                <p><strong>Försäkringsbolag:</strong> <br /><span>{insurance.Forsakringsbolag}</span></p>
                            </div>
                            <div className="column">
                            </div>
                        </div>

                        <div className="block"><hr/></div>

                        <div className="columns">
                            <div className="column">
                                <h6  className="title is-6">Fakturering m.m.</h6>
                            </div>
                            <div className="column">
                                <p><strong>Premier (kr):</strong> <br /><span>{insurance.Premie_kr}</span></p>
                            </div>
                            <div className="column">
                                <p><strong>Provision %:</strong> <br /><span>{insurance.Provision_procent}</span></p>
                            </div>
                            <div className="column">
                                <p><strong>Provision kr:</strong> <br /><span>{insurance.Provision_kr}</span></p>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column is-one-quarter">
                            </div>
                            <div className="column">
                                <p><strong>Särskild faktura rutin:</strong> <br /><span>{insurance.Sarskild_faktura_rutin}</span></p>
                            </div>
                        </div>

                        <div className="block"><hr/></div>
                            {/* divstan
                                <p><strong>Koncern: <span>{insurance.Koncern}</span></p>
                                <p><strong>Organisationsnummer: <span>{insurance.Organisationsnummer}</span></p>
                                <p><strong>Dotterbolag: <span>{insurance.Dotterbolag}</span></p>
                                <p><strong>Dotterbolag organisationsnummer: <span>{insurance.Organisationsnummer_Dotterbolag}</span></p>
                                <p><strong>Övrig information: <span>{insurance.Ovrig_information}</span></p>
                                <p><strong>Dotterbolag: <span>{insurance.Dotterbolag}</span></p>
                                <p><strong>Uppdragsavtalets utgång: <span>{insurance.Uppdragsavtalets_utgang}</span></p>
                                <p><strong>Arvode: <span>{insurance.Arvode}</span></p>
                                <p><strong>Rördivgt: <span>{insurance.Rordivgt}</span></p>
                                <p><strong>Arvode fakturerat: <span>{insurance.Arvode_fakturerat}</span></p>  
                                <p><strong>Sista betalningsdag: <span>{insurance.Sista_betalningsdag}</span></p>                              
                                <p><strong>Kundansvarig: <span>{insurance.Kundansvarig}</span></p>
                                <p><strong>Försäkringsform: <span>{insurance.Forsakringsform}</span></p>
                                <p><strong>Försäkringsnummer: <span>{insurance.Forsakringsnr}</span></p>
                                <p><strong>Förnyelseår: <span>{insurance.F_Ar}</span></p>
                                <p><strong>Förnyelsemånad: <span>{insurance.Fornyelsemanad}</span></p>
                                <p><strong>Förnyelsedag: <span>{insurance.F_dag}</span></p>
                                <p><strong>Försäkringsbolag: <span>{insurance.Forsakringsbolag}</span></p>
                                <p><strong>Premier (kr): <span>{insurance.Premie_kr}</span></p>
                                <p><strong>Provision %: <span>{insurance.Provision_procent}</span></p>
                                <p><strong>Provision kr: <span>{insurance.Provision_kr}</span></p>
                                <p><strong>Särskild faktura rutin: <span>{insurance.Sarskild_faktura_rutin}</span></p>


                                <p><strong>Arbetsprocess Steg 1: <span>{insurance.ARBETSPROCESS_STEG_1}</span></p>
                                <p><strong>Arbetsprocess Steg 2A - Förnyelse: <span>{insurance.ARBETSPROCESS_STEG_2_A_FORNYELSE}</span></p>
                                <p><strong>Arbetsprocess Steg 2B - Upphanddivng: <span>{insurance.ARBETSPROCESS_STEG_2_B_UPPHANDdivNG}</span></p>
                                <p><strong>Arbetsprocess Steg 3 - Placering: <span>{insurance.ARBETSPROCESS_STEG_3_PLACERING}</span></p>
                                <p><strong>Arbetsprocess Steg 4 - ÄNDRINGAR: <span>{insurance.ARBETSPROCESS_STEG_4_ANDRINGAR}</span></p>
                            */}
                            <div className="block">
                                <button className="button is-primary is-outlined left">
                                    <Link
                                        to={{
                                            pathname: `/forsakringar`,
                                        }}>
                                        <FontAwesomeIcon icon="caret-left" /> Tillbaka till Försäkringar
                                    </Link>
                                </button>
                                <button className="button is-primary is-outlined right">
                                    <Link
                                        to={{
                                            pathname: `/redigera-forsakring/${insurance.id}`,
                                        }}>
                                        <FontAwesomeIcon icon="pen" /> Uppdatera försäkring
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Insurance;