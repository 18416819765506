// Lägg till användare
import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
import Link from "react-router-dom/Link";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class AddCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customersRepsonsibility: [],
            customerOrgNr: '',
            customerName: '',
            customerDotterbolag: '',
            customerDotterbolagOrganisationsnummer: '',
            customerKundansvarig: '',
            showSuccess: false
        };

        this.addCustomer = this.addCustomer.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
    }

    addCustomer(event) {
        event.preventDefault();
        var self = this;

        axios.post(getApiUrl() + '/public/addCustomer', {
            Organisationsnummer: this.state.customerOrgNr,
            Koncern: this.state.customerName,
            Kundansvarig: this.state.customerKundansvarig
        })
            .then(function () {
                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        this.getCustomersRepsonsibility();
    }

    getCustomersRepsonsibility() {
        axios.get(getApiUrl() + '/public/getcustomersrepsonsibility')
            .then(response => JSON.parse(JSON.stringify(response)))
            .then(customersrepsonsibility => this.setState({ customersRepsonsibility: customersrepsonsibility.data.customersRepsonsibility }))
            // .then(console.log('test: ' + this.state.customersRepsonsibility))
            .catch(err => console.error('Error ', err.toString()));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCustomerChange(e) {
        this.setState({ customerKundansvarig: e.value });
    }

    render() {
        const message = this.state.showSuccess;

        const options_kundansvarig = this.state.customersRepsonsibility.map(customers => ({
            value: customers.Kundansvarig,
            label: customers.Kundansvarig
        }));

        return (
            <div className="app-content block">
                <div className="block">
                    <div className="columns content-head">
                        <div className="column">
                            <h2 className="title is-large">
                                Skapa ny kund
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="block"></div>
                <form onSubmit={this.addCustomer}>
                    <div className="columns">
                        <div className="column">
                            <h6 className="title is-6">Bolagsinformation</h6>
                        </div>
                        <div className="column">
                            <label className="label">Koncern
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="customerName" required onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                            <label className="label">Organisationsnummer Koncern
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="customerOrgNr" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="column">
                            <label className="label">Kundansvarig
                                <div className="field">
                                    <div className="control">
                                        <Select
                                            placeholder="Kundansvarig"
                                            value={this.state.selectedValueCustomer}
                                            onChange={this.handleCustomerChange}
                                            options={options_kundansvarig}
                                        />
                                        {/* <input className="input" type="text" name="customerKundansvarig" required onChange={this.onChange} /><br /> */}
                                    </div>
                                </div>
                            </label>

                        </div>
                        <div className="column is-3"></div>
                    </div>

                    <div className="block"><hr /></div>

                    <div className="block">
                        {message && (
                            <article className="message is-success">
                                <div className="message-body">
                                    Kunden har skapats!
                                    </div>
                            </article>
                        )}
                        <button className="button is-primary is-outlined left">
                            <Link
                                to={{
                                    pathname: `/kunder`,
                                }}>
                                <FontAwesomeIcon icon="caret-left" /> Tillbaka till Kunder
                            </Link>
                        </button>
                        <button className="button is-primary is-outlined right" type="submit"><FontAwesomeIcon icon="save" /> Spara kund</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default AddCustomer;