import React, { Component } from "react";
import { Link } from "react-router-dom";

// Styling and components
//import Link from "react-router-dom/Link";
import logo from '../images/logo_small_white.png';


class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
    
    // this.logout = this.logout.bind(this);
    // this.onChange = this.onChange.bind(this);
}

  logout(event) {
    console.log('Test');
    event.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/");
  }

  render() {

    let currUserId = JSON.parse(sessionStorage.getItem('userData'));
    // console.log(currUserId.userData);

    return(
        <div className="app-header">
          <div className="columns">
            <div className="column">
              <Link to={{ pathname:"/hem" }}>
                <img src={logo} alt="" className="logo" />
              </Link>
            </div>
            <div className="column alignright">
              <span className="signed-in-as">Inloggad som: 
                <Link to={{ pathname: '/redigera-anvandare/' + currUserId.userData.id }}>{ currUserId.userData.firstname}</Link>
              </span>
              <form className="signed-in" onSubmit={this.logout}>
                <button className="" type="submit">
                {/*<Redirect className="" 
                  to={{
                    pathname:"/"
                }} />*/} Logga ut
                </button>
              </form>
            </div>
          </div>
        </div>
    );
  }
}

export default Header;
