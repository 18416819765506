import React, { Component } from "react";
// import { Switch, Route } from "react-router-dom";

// Styling and components
import Link from "react-router-dom/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        // this.logout = this.logout.bind(this);
        // this.onChange = this.onChange.bind(this);
    }
    
    isAdmin() {
      if(JSON.parse(sessionStorage.getItem('userData')).userData.role === 'Administratör') 
        return true;
      else
        return false;
    }

    render() {
        return (
            <div className="column is-one-quarter is-sidebar-menu is-hidden-mobile app-sidebar">
              <aside className="menu">
                {/* <p className="menu-label">
                  Översikt
                </p>*/}
                <ul className="menu-list">
                  <li><Link to={{ pathname: '/hem'}}>Översikt</Link></li>
                </ul> 
                <p className="menu-label">
                <br/><br/>
                  Försäkringar
                </p>
                <ul className="menu-list">
                  <li>
                    <Link to={{ pathname: '/forsakringar'}}>Lista alla försäkringar</Link>
                    {/* <ul> */}
                      <li>
                      <Link to={{ pathname: `/ny-forsakring` }}>
                            <FontAwesomeIcon icon="plus" /> Skapa ny försäkring
                        </Link>  
                      </li>
                    {/* </ul> */}
                  </li>
                </ul>
                {/* Kunder */}
                <p className="menu-label">
                <br/><br/>
                  Kunder
                </p>
                <ul className="menu-list">
                  <li>
                    <Link to={{ pathname: '/kunder'}}>
                      Lista alla Kunder
                  </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: '/dotterbolag'}}>
                      Lista alla Dotterbolag
                  </Link>
                  {/* <ul> */}
                  <li>
                      <Link to={{ pathname: `/ny-kund` }}>
                            <FontAwesomeIcon icon="plus" /> Lägg till Kund
                        </Link>  
                      </li>
                    {/* </ul> */}
                  {/* <ul> */}
                      <li>
                      <Link to={{ pathname: `/nytt-dotterbolag` }}>
                            <FontAwesomeIcon icon="plus" /> Lägg till Dotterbolag
                        </Link>  
                      </li>
                    {/* </ul> */}
                  </li>
                </ul>
                {this.isAdmin() ? (
                  <div>
                    <p className="menu-label">
                    <br/><br/>
                      Administration
                    </p>
                    <ul className="menu-list">
                      <li>
                        <Link to={{ pathname: '/anvandare'}}>Lista alla Användare</Link>
                        {/* <ul> */}
                          <li>
                            <Link to={{ pathname: `/registrera-anvandare` }}>
                                <FontAwesomeIcon icon="plus" /> Lägg till användare
                            </Link>
                          </li>
                        {/* </ul> */}
                      </li>
                      {/* className="is-active" */}
                    </ul>
                  </div>
                  ) : (
                    <div></div>
                )}
              </aside>
            </div>
        );
    }
}

export default Sidebar;
