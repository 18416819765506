// Lägg till användare
import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import Link from "react-router-dom/Link";
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';
// import Insurance from './Insurance';

class Insurances extends Component {

    state = {
        insurances: [],
        customersRepsonsibility: [],
        visibleInsurances: 10,
        insurance: [],
        searchValue: '',
        toggledComp: false,
        selectValue: null,
        selectedOption: null,
        items: [],
        sortByDate: false,
        toggleExpiredState: false,
        toggleArchived: false,
        startDate: null,
        endDate: null,
        filterStartDate: new Date(),
        filterEndDate: new Date()
    }

    handleSearch = this.handleSearch.bind(this);
    toggleFilter = this.toggleFilter.bind(this);
    updateFilteredList = this.updateFilteredList.bind(this);
    handleChange = this.handleChange.bind(this);
    toggleDate = this.toggleDate.bind(this);
    toggleExpired = this.toggleExpired.bind(this);
    toggleArchived = this.toggleArchived.bind(this);
    handleDateStartRange = this.handleDateStartRange.bind(this);
    handleDateEndRange = this.handleDateEndRange.bind(this);
    clearFilters = this.clearFilters.bind(this);
    loadMore = this.loadMore.bind(this);
    setSearchQuery = this.setSearchQuery.bind(this);

    // Load insurances
    loadMore() {
        this.setState((prev) => {
            return { visibleInsurances: prev.visibleInsurances + 10 };
        });
    }
    /**
    * Handels input form search field
    * @param  {[string]} e [event handler]
    */
    handleSearch(e) {
        e.preventDefault();
        this.setState({
            searchValue: e.target.value
        }, () => {
            this.updateFilteredList();
        });

        localStorage.setItem('searchFilterQuery', JSON.stringify(e.target.value));
    }

    handleDateStartRange(startDate) {
        let formatedStartDate = moment(startDate).toDate();

        this.setState({
            filterStartDate: formatedStartDate,
            startDate: moment(startDate).format('X')
        }, () => {
            this.updateFilteredList();
        });
        console.log("Start:" + startDate);
    }

    handleDateEndRange(endDate) {
        let formatedEndDate = moment(endDate).toDate();

        this.setState({
            filterEndDate: formatedEndDate,
            endDate: moment(endDate).format('X')
        }, () => {
            this.updateFilteredList();
        });
        console.log("End:" + endDate);
    }

    clearFilters() {
        this.setState({
            startDate: null,
            endDate: null,
            filterStartDate: new Date(),
            filterEndDate: new Date()
        }, () => {
            this.updateFilteredList();
        });

    }

    toggleDate() {
        let sortedList = this.state.items;

        if (!this.state.sortByDate) {
            sortedList.sort(function (a, b) {
                return (a === '') - (b === '') || +(new Date(a.Fornyelse).getTime() > new Date(b.Fornyelse).getTime()) || -(new Date(a.Fornyelse).getTime() < new Date(b.Fornyelse).getTime());
            });
        }

        this.setState(prevState => ({
            items: sortedList,
            sortByDate: !prevState.sortByDate
        }));
    }

    stateByDate(theDate) {
        let theClass = '';

        let theDateTimestamp = moment(theDate).unix();
        let currentDate = moment().unix();
        let futureMonth3 = moment().add(3, 'M').unix();

        if (theDateTimestamp < currentDate) {
            theClass = 'date-expired';
        } else if (theDateTimestamp > currentDate && theDateTimestamp < futureMonth3) {
            theClass = 'date-alert';
        } else {
            theClass = 'date-normal';
        }

        return theClass;
    }

    toggleExpired() {
        // let expired = this.state.toggleExpired;
        this.setState({ toggleExpiredState: !this.state.toggleExpiredState });
    }

    updateFilteredList() {
        let self = this;
        let updatedList = this.state.insurances;

        if (self.state.startDate !== null && self.state.endDate !== null) {
            //    if (self.state.selectValue === 'none') return self.setState({ items: self.state.insurances });
            updatedList = updatedList.filter(function (item) {
                return moment(item.Fornyelse).format('X') >= self.state.startDate && moment(item.Fornyelse).format('X') <= self.state.endDate;
                //return item.Kundansvarig === self.state.selectValue;
            });
        }

        if (self.state.selectValue !== null) {
            if (self.state.selectValue === 'none') return self.setState({ items: self.state.insurances });
            updatedList = updatedList.filter(function (item) {
                return item.Kundansvarig === self.state.selectValue;
            });
        }

        updatedList = updatedList.filter(function (item) {
            return item.Koncern.toLowerCase().indexOf(self.state.searchValue.toLowerCase()) !== -1 || (typeof item.Dotterbolag === 'string' && item.Dotterbolag.toLowerCase().indexOf(self.state.searchValue.toLowerCase()) !== -1);
        });

        this.setState({ items: updatedList });
    }

    handleChange(selectedOption) {
        this.setState({
            selectValue: selectedOption.value
        }, () => {
            this.updateFilteredList();
        });
    }

    toggleFilter(filterBox) {
        this.setState(prevState => ({
            toggledComp: !prevState.toggledComp
        }));
    }

    toggleArchived() {
        this.setState(prevState => ({
            toggleArchived: !prevState.toggleArchived
        }));
    }

    getCustomersRepsonsibility() {
        axios.get(getApiUrl() + '/public/getcustomersrepsonsibility')
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(customersrepsonsibility => this.setState({ customersRepsonsibility: customersrepsonsibility.data.customersRepsonsibility }))
        // .then(console.log('test: ' + this.state.customersRepsonsibility))
        .catch(err => console.error('Error ', err.toString()));
    }

    getInsuranceData() {
        axios.get(getApiUrl() + '/public/insurances')
            .then(response => JSON.parse(JSON.stringify(response)))
            .then(insurances => this.setState({ insurances: insurances.data.insurances }))
            .then(() => this.setState({ items: this.state.insurances }))
            .then(() => this.setSearchQuery())
            .catch(err => console.error('Error ', err.toString()));
    }

    componentDidMount = () => {
        this.getInsuranceData();
        this.getCustomersRepsonsibility();
    }

    setSearchQuery() {
        const searchJsonQuery = localStorage.getItem('searchFilterQuery');
        const insuranceSearchResult = JSON.parse(searchJsonQuery);
        
        if(insuranceSearchResult.length !== 0) {
            this.setState({
                searchValue: insuranceSearchResult
            }, () => {
                this.updateFilteredList();
            });
        }
    }

    componentDidUpdate = () => {
        // const insurances = JSON.stringify(this.state.insurances);
        //localStorage.setItem("insurances", insurances);
    }

    render() {
        const options_kundansvarig = this.state.customersRepsonsibility.map(customers => ({
            value: customers.Kundansvarig,
            label: customers.Kundansvarig
        }));

        let list = this.state.items.slice(0, this.state.visibleInsurances).map((insurance, index) => {
            return (
                <div className={"card insurance-card " + this.stateByDate(insurance.Fornyelse) + " " + (this.state.toggleExpiredState ? 'hide-expired' : '') + " arkiverad-" + insurance.Arkiverad + " " + (this.state.toggleArchived ? 'visa-arkiverade' : '')}
                    key={index}>
                    <div className="card-header">

                        <h4 className="card-title title is-5">
                            <Link to={{ pathname: `/forsakring/${insurance.id}`, state: { insurance: insurance.Koncern } }}>{insurance.Koncern}</Link>
                            <span className="status"><FontAwesomeIcon icon="circle" /></span>
                            <span className="right">
                                <Link
                                    to={{
                                        pathname: `/redigera-forsakring/${insurance.id}`,
                                    }}
                                    className="button is-white is-outlined is-small">
                                    <FontAwesomeIcon icon="pen" /> Redigera
                                </Link>
                            </span>
                        </h4>
                    </div>
                    <div className="card-content">
                        <div className="columns">
                            <div className="column">
                                <h6 className="title is-6">Bolagsinformation</h6>
                                <p><strong>Organisationsnummer:</strong> <span>{insurance.Organisationsnummer}</span></p>
                                <p><strong>Dotterbolag:</strong> <span>{insurance.Dotterbolag}</span></p>
                                <p><strong>Dotterbolag organisationsnummer:</strong> <span>{insurance.Organisationsnummer_Dotterbolag}</span></p>
                            </div>
                            <div className="column">
                                <h6 className="title is-6">Försäkring</h6>
                                <p><strong>Försäkringsform:</strong> <span>{insurance.Forsakringsform}</span></p>
                                <p><strong>Försäkringsnummer:</strong> <span>{insurance.Forsakringsnr}</span></p>
                                <p><strong>Försäkringsbolag:</strong> <span>{insurance.Forsakringsbolag}</span></p>
                                <p><strong>Övrig information:</strong> <span>{insurance.Ovrig_information}</span></p>
                            </div>
                            <div className="column">
                                <h6 className="title is-6">Uppdrag & Avtal</h6>
                                <p><strong>Kundansvarig:</strong> <span>{insurance.Kundansvarig}</span></p>
                                <p><strong>Uppdragsavtalets utgång:</strong> <span>{insurance.Uppdragsavtalets_utgang}</span></p>
                                <p><strong>Huvudförfallodag:</strong> <span>{insurance.Fornyelse}</span></p>
                                <p><strong>Premie (kr):</strong> <span>{insurance.Premie_kr}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className="app-content block">
                <div className="columns content-head">
                    <div className="column">
                        <h2 className="title is-large">Alla försäkringar</h2>
                    </div>
                    <div className="column">
                        <button className="right button is-primary is-outlined">
                            <Link
                                to={{
                                    pathname: `/ny-forsakring`
                                }}>
                                <FontAwesomeIcon icon="plus" /> Lägg till försäkring
                            </Link>
                        </button>
                    </div>
                </div>
                <div className="block box content-filter">
                    <div className="columns">
                        <div className="column is-7">
                            <div className="field">
                                <div className="control">
                                    <form>
                                        <input className="search input" type="text" name="insuranceName" value={this.state.searchValue} placeholder="Sök.." onChange={this.handleSearch} />
                                    </form>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    <span className="select-field">
                                        <Select
                                            placeholder="Namn"
                                            value={this.state.selectedValue}
                                            onChange={this.handleChange}
                                            options={options_kundansvarig}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <label className="label right">Huvudförfallodag från.. <br />
                                <DatePicker
                                    className="input"
                                    selected={this.state.filterStartDate}
                                    selectsStart
                                    startDate={this.state.filterStartDate}
                                    endDate={this.state.filterEndDate}
                                    onChange={this.handleDateStartRange}
                                    dateFormat="yyyy/MM/dd"
                                />
                            </label>
                        </div>
                        <div className="column">
                            <label className="label right">.. till och med<br />
                                <DatePicker
                                    className="input"
                                    selected={this.state.filterEndDate}
                                    selectsEnd
                                    startDate={this.state.filterStartDate}
                                    endDate={this.state.filterEndDate}
                                    onChange={this.handleDateEndRange}
                                    minDate={this.state.filterStartDate}
                                    dateFormat="yyyy/MM/dd"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <div className="field">
                                <div className="control">
                                    <div className="b-checkbox is-primary">
                                        <label className="label">
                                            <input id="checkbox" className="styled" type="checkbox" value={this.state.toggleExpiredState} onChange={this.toggleExpired} />
                                            Dölj utgågna
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field">
                                <div className="control">
                                    <div className="b-checkbox is-primary">
                                        <label className="label">
                                            <input id="checkbox" className="styled" type="checkbox" onClick={this.toggleArchived} />
                                            Visa Arkiverade
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field">
                                <div className="control">
                                    <div className="b-checkbox is-primary">
                                        <label className="label">
                                            <input id="checkbox" className="styled" type="checkbox" onClick={this.toggleDate} />
                                            Datumordning
                                                </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column is-5">
                            <button className="button is-small is-primary is-outlined right" onClick={this.clearFilters}>Rensa datum</button>
                        </div>
                    </div>
                </div>
            <div className="block content-body">{list}</div>
            <div>
                {this.state.visibleInsurances < this.state.items.length &&
                    <button onClick={this.loadMore} type="button" className="load-more button is-primary is-outlined">Visa fler försäkringar</button>
                }
            </div>
            </div >
        );
    }

}

export default Insurances;