import React, { Component } from "react";
// import 'bootstrap/dist/css/bootstrap.css';
import Link from "react-router-dom/Link";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faList, faUsers, faUser, faFile, faCog, faCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faUtensils, faList, faUsers, faUser, faFile, faCog, faCheck);

class Home extends Component {
    state = {

    }

    isAdmin() {
        if (JSON.parse(sessionStorage.getItem('userData')).userData.role === 'Administratör')
            return true;
        else
            return false;
    }

    render() {
        return (
            <div className="app-content">
                {/* <div className="columns">
                    <div className="column">
                        <strong>Provision 2019</strong><br/>
                        <span>234 000 kr</span>
                    </div>
                    <div className="column">
                        <strong>Nya kunder under [Månad]</strong><br/>
                        <span>2 kunder</span>
                    </div>
                    <div className="column">
                        <strong></strong><br/>
                        <span>2 kunder</span>
                    </div>
                </div> */}
                <div className="columns">
                    {/* Kunder */}
                    <div className="column">
                        <Link to="/kunder" className="app-component-link">
                            <div className="app-component-icon">
                                <FontAwesomeIcon icon="users" />
                            </div>
                            <div className="app-component-name">Kunder</div>
                        </Link>
                    </div>

                    <div className="column">
                        <Link to="/forsakringar" className="app-component-link">
                            <div className="app-component-icon">
                                <FontAwesomeIcon icon="file" />
                            </div>
                            <div className="app-component-name">Försäkringar</div>
                        </Link>
                    </div>

                    {/* Användare */}
                    {this.isAdmin() ? (
                    <div className="column">
                        <Link to="/anvandare" className="app-component-link">
                            <div className="app-component-icon">
                                <FontAwesomeIcon icon="user" />
                            </div>
                            <div className="app-component-name">Användare</div>
                        </Link>
                    </div>
                    ) : (
                        <div></div>
                    )}
                    {/* <div className="column">
                        <Link to="/installningar" className="app-component-link">
                            <div className="app-component-icon">
                                <FontAwesomeIcon icon="cog" />
                            </div>
                            <div className="app-component-name">Inställningar</div>
                        </Link>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Home;