// Lägg till användare
import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import Link from "react-router-dom/Link";
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class EditInsurance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            dotterbolag: [],
            DotterBolagen: [],
            customersData: [],
            dotterbolagData: [],
            customersKoncernId: '',
            dotterbolagId: '',

            activeInsurance: [],
            inputValue: '',

            insuranceKoncernId: '',
            insuranceDotterbolagId: '',
            insuranceForsakringsform: '',

            insuranceProjektforsakring: '',
            insuranceForsakringsnummer: '',
            insuranceForsakringsbolag: '',
            insuranceKundansvarig: '',
            insuranceUppdragUtgang: new Date(),

            insuranceOvrigInfo: '',
            insuranceArvode: '',
            insuranceRorligt: '',
            insuranceArvodeFakturerat: '',
            insuranceFornyelse: new Date(),

            insurancePremieKr: '',
            insuranceProvisionProcent: '',
            insuranceProvisionKr: '',
            insuranceSarskildFakturaRutin: '',
            insuranceAPS1: '',

            insuranceAPS2AF: '',
            insuranceAPS2BU: '',
            insuranceAPS3P: '',
            insuranceAPS4P: '',
            insuranceArchived: false,
            showSuccess: false,
            selectedValueCustomer: ''
        };

        this.updateInsurance = this.updateInsurance.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleSubCustomerChange = this.handleSubCustomerChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleNewDateChange = this.handleNewDateChange.bind(this);
        this.fetchSubCustomer = this.fetchSubCustomer.bind(this);
        this.handleArchived = this.handleArchived.bind(this);
    }

    updateInsurance(event) {
        event.preventDefault();
        let insuranceKoncernId = !this.state.insuranceKoncernId ? this.state.activeInsurance.Koncern_id : this.state.insuranceKoncernId;
        let insuranceDotterbolagId = !this.state.insuranceDotterbolagId ? this.state.activeInsurance.Dotterbolag_id : this.state.insuranceDotterbolagId;
        let insuranceForsakringsform = !this.state.insuranceForsakringsform ? this.state.activeInsurance.Forsakringsform : this.state.insuranceForsakringsform;
        let insuranceProjektforsakring = !this.state.insuranceProjektforsakring ? this.state.activeInsurance.Projektforsakring : this.state.insuranceProjektforsakring;
        let insuranceForsakringsnummer = !this.state.insuranceForsakringsnummer ? this.state.activeInsurance.Forsakringsnr : this.state.insuranceForsakringsnummer;
        
        let insuranceForsakringsbolag = !this.state.insuranceForsakringsbolag ? this.state.activeInsurance.Forsakringsbolag : this.state.insuranceForsakringsbolag;
        let insuranceUppdragUtgang = !this.state.insuranceUppdragUtgang ? this.state.activeInsurance.Uppdragsavtalets_utgang : this.state.insuranceUppdragUtgang;
        let insuranceOvrigInfo = !this.state.insuranceOvrigInfo ? this.state.activeInsurance.Ovrig_information : this.state.insuranceOvrigInfo;
        let insuranceArvode = !this.state.insuranceArvode ? this.state.activeInsurance.Arvode : this.state.insuranceArvode;
        let insuranceRorligt = !this.state.insuranceRorligt ? this.state.activeInsurance.Rorligt : this.state.insuranceRorligt;
        
        let insuranceArvodeFakturerat = !this.state.insuranceArvodeFakturerat ? this.state.activeInsurance.arvodefakt : this.state.insuranceArvodeFakturerat;
        let insuranceFornyelse = !this.state.insuranceFornyelse ? this.state.activeInsurance.Fornyelse : this.state.insuranceFornyelse;
        let insurancePremieKr = !this.state.insurancePremieKr ? this.state.activeInsurance.Premie_kr : this.state.insurancePremieKr;
        let insuranceProvisionProcent = !this.state.insuranceProvisionProcent ? this.state.activeInsurance.Provision_procent : this.state.insuranceProvisionProcent;
        let insuranceSarskildFakturaRutin = !this.state.insuranceSarskildFakturaRutin ? this.state.activeInsurance.sarskildfaktrutin : this.state.insuranceSarskildFakturaRutin;
        
        let insuranceAPS1 = !this.state.insuranceAPS1 ? this.state.activeInsurance.ARBETSPROCESS_STEG_1 : this.state.insuranceAPS1;
        let insuranceAPS2AF = !this.state.insuranceAPS2AF ? this.state.activeInsurance.ARBETSPROCESS_STEG_2_A_FORNYELSE : this.state.insuranceAPS2AF;
        let insuranceAPS2BU = !this.state.insuranceAPS2BU ? this.state.activeInsurance.ARBETSPROCESS_STEG_2_B_UPPHANDLING : this.state.insuranceAPS2BU;
        let insuranceAPS3P = !this.state.insuranceAPS3P ? this.state.activeInsurance.ARBETSPROCESS_STEG_3_PLACERING : this.state.insuranceAPS3P;
        let insuranceAPS4P = !this.state.insuranceAPS4P ? this.state.activeInsurance.ARBETSPROCESS_STEG_4_ANDRING : this.state.insuranceAPS4P;

        var self = this;
        axios.post(getApiUrl() + '/public/editInsurance', {
            Koncern_id: insuranceKoncernId,
            Dotterbolag_id: insuranceDotterbolagId,
            Ovrig_information: insuranceOvrigInfo,
            Uppdragsavtalets_utgang: moment(insuranceUppdragUtgang).format('YYYY-MM-DD'),
            Arvode: insuranceArvode,

            Rorligt: insuranceRorligt,
            Arvode_fakturerat: insuranceArvodeFakturerat,
            Forsakringsform: insuranceForsakringsform,
            Projektforsakring: insuranceProjektforsakring,
            Forsakringsnr: insuranceForsakringsnummer,

            Fornyelse: moment(insuranceFornyelse).format('YYYY-MM-DD'),
            Forsakringsbolag: insuranceForsakringsbolag,
            Premie_kr: insurancePremieKr,
            Provision_procent: insuranceProvisionProcent,
            Provision_kr: ((insurancePremieKr / 100) * insuranceProvisionProcent),

            Sarskild_faktura_rutin: insuranceSarskildFakturaRutin,
            ARBETSPROCESS_STEG_1: insuranceAPS1,
            ARBETSPROCESS_STEG_2_A_FORNYELSE: insuranceAPS2AF,
            ARBETSPROCESS_STEG_2_B_UPPHANDLING: insuranceAPS2BU,
            ARBETSPROCESS_STEG_3_PLACERING: insuranceAPS3P,

            ARBETSPROCESS_STEG_4_ANDRING: insuranceAPS4P,
            Arkiverad: Number(this.state.insuranceArchived),
            insuranceid: this.state.activeInsurance.id
        })
            .then(function () {

                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    onChange(e) {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        this.setState({
            [e.target.name]: value
        });
    }

    componentDidMount() {
        this.getCustomerData();
        this.getSingleInsurance();
    }

    getSingleInsurance() {
        const { match: { params } } = this.props;

        axios.get(getApiUrl() + '/public/singleInsurance?insuranceid=' + params.id)
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(insurancesdata => this.setState({ 
            activeInsurance: insurancesdata.data.insuranceData, 
            insuranceFornyelse: insurancesdata.data.insuranceData.Fornyelse,
            insuranceUppdragUtgang: insurancesdata.data.insuranceData.Uppdragsavtalets_utgang,
            insuranceArchived: Boolean(Number(insurancesdata.data.insuranceData.Arkiverad)) 
        }))
        .catch(err => console.error('Error ', err.toString()));
    }

    getCustomerData() {
        axios.get(getApiUrl() + '/public/getcustomersdata')
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(customersdata => this.setState({ customersData: customersdata.data.customersData }))
        .catch(err => console.error('Error ', err.toString()));
    }

    handleArchived() {
        this.setState(prevState => ({
            insuranceArchived: !prevState.insuranceArchived
        }));

        console.log(!this.state.insuranceArchived);
    }

    handleNewDateChange(date) {
        this.setState({ insuranceFornyelse: date });
    }

    handleEndDateChange(date) {
        this.setState({ insuranceUppdragUtgang: date });
    }

    fetchSubCustomer(customer) {
        axios.get(getApiUrl() + '/public/singleSubCustomer?koncern=' + customer)
        .then(response => JSON.parse(JSON.stringify(response)))
        .then((res) =>{
            this.setState({
                DotterBolagen: res.data.dotterbolagData.map(data => ({
                    Namn: data.Dotterbolag,
                    ID: data.Dotterbolag_id
                }))
            });
        })
    }

    handleCustomerChange(event) {
        axios.get(getApiUrl() + '/public/singleCustomer?koncern=' + event.label)
            .then(response => JSON.parse(JSON.stringify(response)))
            .then(koncerndata => this.setState({ 
                insuranceKoncernId: koncerndata.data.koncernData.Koncern_id, 
                selectedValueCustomer: koncerndata.data.koncernData.Koncern 
            }))
            .then(this.fetchSubCustomer(event.value))
    }

    handleSubCustomerChange(event) {
        this.setState({
            insuranceDotterbolagId: event.value
        });
    }

    render() {
        const insurance = this.state.activeInsurance;
        const message = this.state.showSuccess;
        let procentValueStart = insurance.Provision_kr;

        let insurancePrem1 = !this.state.insurancePremieKr ? insurance.Premie_kr : this.state.insurancePremieKr;
        let insurancePrem2 = !this.state.insuranceProvisionProcent ? insurance.Provision_procent : this.state.insuranceProvisionProcent;

        let procentValue = (insurancePrem1 / 100) * insurancePrem2;

        const options = this.state.customersData.map(customers => ({
            value: customers.Koncern_id,
            label: customers.Koncern,
            id: customers.Koncern_id
        }));

        const options_subcustomer = this.state.DotterBolagen.map(dotterbolag => ({
            value: dotterbolag.ID,
            label: dotterbolag.Namn
        }));

        return (

            <div className="app-content block">
                {this.state.activeInsurance.length !== 0 &&

                    <div className="block">
                        <div className="columns content-head">
                            <div className="column">
                                <h2 className="title is-large">
                                    Uppdatera försäkring
                                </h2>
                            </div>
                        </div>

                        <div className="block"></div>
                        <form>
                            <div className="columns">
                                <div className="column">
                                    <h6 className="title is-6">
                                        <label>
                                            <input type="checkbox" name="insuranceArchived" checked={this.state.insuranceArchived} value={this.state.insuranceArchived} onChange={this.handleArchived} /> Arkivera försäkring
                                        </label>
                                    </h6>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <h6 className="title is-6">Bolagsinformation</h6>
                                </div>
                                <div className="column is-6">
                                    <label className="label">Koncern
                                        <div className="field">
                                            <div className="control">
                                                <span className="select-field">
                                                    <Select
                                                        placeholder="Koncern"
                                                        value={this.state.selectedValueCustomer}
                                                        onChange={this.handleCustomerChange}
                                                        options={options}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3">
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-3"></div>
                                <div className="column is-6">
                                    <label className="label">Dotterbolag
                                    <div className="field">
                                            <div className="control">
                                                <span className="select-field">
                                                    <Select
                                                        placeholder="Dotterbolag"
                                                        value={this.state.selectedValueSubCustomer}
                                                        onChange={this.handleSubCustomerChange}
                                                        options={options_subcustomer}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3"></div>
                            </div>

                            <div className="block"><hr /></div>

                            <div className="columns is-multiline">
                                <div className="column is-3">
                                    <h6 className="title is-6">Uppdrag & Avtal</h6>
                                </div>
                                <div className="column is-3">
                                    <label className="label">Arvode
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="number" name="insuranceArvode" defaultValue={insurance.Arvode} onChange={this.onChange} /><br />
                                            </div></div></label>
                                </div>
                                <div className="column is-3">
                                    <label className="label">Arvode fakturerat
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="insuranceArvodeFakturerat" defaultValue={insurance.Arvode_fakturerat} onChange={this.onChange} /><br />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3">
                                    <label className="label">Rörligt
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="number" name="insuranceRorligt" defaultValue={insurance.Rorligt} onChange={this.onChange} /><br />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-3"></div>
                                <div className="column is-3">
                                    <label className="label">Uppdragsavtalets utgång
                                        <div className="field">
                                            <div className="control">
                                                <DatePicker
                                                    className="input"
                                                    selected={Date.parse(this.state.insuranceUppdragUtgang)}
                                                    onChange={this.handleEndDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3">
                                    <label className="label">Förnyelsedatum
                                    <div className="field">
                                            <div className="control">
                                                <DatePicker
                                                    className="input"
                                                    selected={Date.parse(this.state.insuranceFornyelse)}
                                                    onChange={this.handleNewDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3"></div>
                            </div>

                            <div className="block"><hr /></div>

                            <div className="columns is-multiline">
                                <div className="column is-3">
                                    <h6 className="title is-6">Försäkring</h6>
                                </div>

                                <div className="column is-3">
                                    <label className="label">Försäkringsform
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="insuranceForsakringsform" defaultValue={insurance.Forsakringsform} onChange={this.onChange} /><br />
                                            </div></div></label>
                                </div>
                                <div className="column is-3">

                                    <label className="label">Projektförsäkring
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="insuranceProjektforsakring" defaultValue={insurance.Projektforsakring} onChange={this.onChange} />
                                            </div></div></label>

                                </div>
                                <div className="column is-3">
                                </div>
                                <div className="column is-3">
                                </div>
                                <div className="column is-3">
                                    <label className="label">Försäkringsnummer
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="insuranceForsakringsnummer" defaultValue={insurance.Forsakringsnr} onChange={this.onChange} /><br />
                                            </div></div></label>
                                </div>
                                <div className="column is-3">
                                    <label className="label">Försäkringsbolag
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="insuranceForsakringsbolag" defaultValue={insurance.Forsakringsbolag} onChange={this.onChange} /><br />
                                            </div></div></label>
                                </div>
                                <div className="column is-3">
                                </div>
                            </div>

                            <div className="block"><hr /></div>

                            <div className="columns">
                                <div className="column">
                                    <h6 className="title is-6">Fakturering m.m.</h6>
                                </div>
                                <div className="column">
                                    <label className="label">Premier (kr)
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="number" name="insurancePremieKr" defaultValue={insurance.Premie_kr} onChange={this.onChange} /><br />
                                            </div></div></label>
                                </div>
                                <div className="column">
                                    <label className="label">Provision %
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="number" name="insuranceProvisionProcent" defaultValue={insurance.Provision_procent} onChange={this.onChange} /><br />
                                            </div></div></label>
                                </div>
                                <div className="column">
                                    <label className="label">Provision kr

                                    {/* Percentage */}
                                        <p>{procentValue ? procentValue : procentValueStart}</p><br />
                                    </label>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-3"></div>
                                <div className="column">
                                    <label className="label">Särskild fakturarutin
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="insuranceSarskildFakturaRutin" defaultValue={insurance.Sarskild_faktura_rutin} onChange={this.onChange} />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3"></div>
                            </div>

                            <div className="columns">
                                <div className="column is-3"></div>
                                <div className="column">
                                    <div className="field">
                                        <div className="control">
                                            <textarea className="textarea" name="insuranceOvrigInfo" placeholder="Övrig information" defaultValue={insurance.Ovrig_information} onChange={this.onChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="block"><hr /></div>

                            <div className="block">
                                {message && (
                                    <article className="message is-success">
                                        <div className="message-body">
                                            Försäkringen har uppdaterats!
                                    </div>
                                    </article>
                                )}
                                <button className="button is-primary is-outlined left">
                                    <Link
                                        to={{
                                            pathname: `/forsakringar`,
                                        }}>
                                        <FontAwesomeIcon icon="caret-left" /> Tillbaka till Försäkringar
                                    </Link>
                                </button>
                                <button className="button is-primary is-outlined right" onClick={this.updateInsurance}>
                                    <FontAwesomeIcon icon="save" /> Spara
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        );
    }
}

export default EditInsurance;