import React, { Component } from "react";
// import { Switch, Route } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.css';
import Link from "react-router-dom/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';
// import cors from 'cors';

class Users extends Component {

    state = {
        users: []
    };

    componentDidMount = () => {
        axios.get(getApiUrl() + '/public/users')
            .then(response => JSON.parse(JSON.stringify(response)))
            .then(users => this.setState({ users: users.data.users }))
            .catch(err => console.error('Error ', err.toString()));

    }
    componentDidUpdate = () => {
        const users = JSON.stringify(this.state.users);
        localStorage.setItem("users", users);
    }

    render() {
        const userlist = this.state.users;
        // console.log(userlist)
        let users = userlist.map((user, index) => {
            return (
                <div className="card customer-card"
                    key={index}>
                    <div className="card-header">
                        <h4 className="card-title title is-5">
                            {/* <Link to={{ pathname: `/user/${user.id}` }}>{user.username}</Link> */}
                            {user.username}
                            <span className="right">
                                <Link
                                    to={{
                                        pathname: `/redigera-anvandare/${user.id}`,
                                    }}
                                    className="button is-white is-outlined is-small">
                                    <FontAwesomeIcon icon="pen" /> Redigera användare
                                </Link>
                            </span>
                        </h4>
                    </div>
                    <div className="card-content">
                        <div className="columns"><hr/>
                            <div className="column">
                                <p><strong>Förnamn:</strong> <br /><span>{user.firstname}</span></p>
                                <p><strong>Efternamn:</strong> <br /><span>{user.lastname}</span></p>
                            </div>
                            <div className="column">
                                <p><strong>Användarnamn:</strong> <br /><span>{user.username}</span></p>
                                <p><strong>E-post:</strong> <br /><span>{user.email}</span></p>
                            </div>
                            <div className="column">
                                <p><strong>Roll:</strong> <br /><span>{user.role}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className="app-content block">
                <div className="columns content-head">
                    <div className="column">
                        <h2 className="title is-large">Alla användare</h2>
                    </div>
                    <div className="column">
                        <button className="right button is-primary is-outlined">
                            <Link
                                to={{
                                    pathname: `/registrera-anvandare`
                                }}>
                                <FontAwesomeIcon icon="plus" /> Lägg till användare
                            </Link>
                        </button>
                    </div>
                </div>
                <div className="block content-body">
                    {users}
                </div>
            </div>
        );
    }
}

export default Users;