import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Styling and components
// import Link from "react-router-dom/Link";
// import 'bootstrap/dist/css/bootstrap.css';
import "./styles/index.css";
// import "./App.sass";
// import logo from './images/logo_small_white.png';

// System
import Home from "./components/Home";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Login from "./components/Login";
import Installningar from "./components/Installningar";

// Customers & Agreements
import Customers from "./components/Customers"; // All
import Customer from "./components/Customer";
import AddCustomer from "./components/AddCustomer";
import EditCustomer from "./components/EditCustomer";

import SubCustomers from "./components/SubCustomers";
import SubCustomer from "./components/SubCustomer";
import AddSubCustomer from "./components/AddSubCustomer";
import EditSubCustomer from "./components/EditSubCustomer";

import Insurances from "./components/Insurances"; // All

import Insurance from "./components/Insurance";
import AddInsurance from "./components/AddInsurance";
import EditInsurance from "./components/EditInsurance";

// import Avtal from "./components/Avtal";
// import Dokument from "./components/Dokument";

// Users
import AddUser from "./components/AddUser";
import Users from "./components/Users";
import EditUser from "./components/EditUser";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faCaretLeft, faPen, faSave, faArchive, faExclamationCircle, faCheckCircle, faAdjust, faSearch, faFile, faHome, faCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
library.add(faPlus, faCaretLeft, faPen, faSave, faArchive, faExclamationCircle, faCheckCircle, faAdjust, faSearch, faFile, faHome, faCircle, faTrash);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };
    
    // this.logout = this.logout.bind(this);
    // this.onChange = this.onChange.bind(this);
}

  logout(event) {
    console.log('Test');
    event.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
  }

  render() {
    if (!sessionStorage.getItem('userData')) {
      return (
        <Switch>
          <Route exact={true} path='/' component={Login} />
          <Redirect to='/' />
        </Switch>
      );
   }
    return (
      <div className="app">
        {/* Header */}
        {sessionStorage.getItem('userData') == null ? (<div></div>) : ( <Header /> )}

        {/* Content */}
        <div className="app-body">
          <div className="columns is-fullheight">
            {/* Sidebar */}
            {sessionStorage.getItem('userData') == null ? (<div></div>) : ( <Sidebar /> )}

            <div className="column is-main-content is-four-quarters">
                  <Switch>
                    <Route exact path="/" component={Login} />
                    <Route path="/hem" component={Home} />
                    <Route path="/installningar" component={Installningar} />

                    {/* Kunder */}
                    <Route path="/kunder" component={Customers} />
                    <Route path="/kund/:id" component={Customer}/>
                    <Route path="/ny-kund" component={AddCustomer} />
                    <Route path="/redigera-kund/:id" component={EditCustomer} />

                    {/* Dotterbolag */}
                    <Route path="/dotterbolag" component={SubCustomers} />
                    <Route path="/dotterbolaget/:id" component={SubCustomer} />
                    <Route path="/nytt-dotterbolag" component={AddSubCustomer} />
                    <Route path="/redigera-dotterbolag/:id" component={EditSubCustomer} />

                    {/* Försäkringar */}
                    <Route path="/forsakringar" component={Insurances} />
                    <Route path="/forsakring/:id" component={Insurance} />
                    <Route path="/ny-forsakring" component={AddInsurance} />
                    <Route path="/redigera-forsakring/:id" component={EditInsurance} />

                    {/* Användare */}
                    <Route path="/anvandare" component={Users} />
                    <Route path="/redigera-anvandare/:id" component={EditUser} />
                    <Route path="/registrera-anvandare" component={AddUser} />
                    
                  </Switch>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default App;
