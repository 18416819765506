// Lägg till användare
import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
import Link from "react-router-dom/Link";
import Select from 'react-select';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class AddSubCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customersData: [],
            customerKoncernId: '',
            customerDotterbolag: '',
            customerAnsvarig: '',
            customerDotterbolagOrganisationsnummer: '',
            showSuccess: false
        };

        this.addCustomer = this.addCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleCustomerChange(e) {
        this.setState({
            customerKoncernId: e.id,
            customerAnsvarig: e.Kundansvarig
        });
    }

    addCustomer(event) {
        event.preventDefault();
        var self = this;

        axios.post(getApiUrl() + '/public/addSubCustomer', {
            Organisationsnummer_Dotterbolag: this.state.customerDotterbolagOrganisationsnummer,
            Dotterbolag: this.state.customerDotterbolag,
            Koncern_id: this.state.customerKoncernId,
            Kundansvarig: this.state.customerAnsvarig
        })
            .then(function () {
                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.getCustomerData();
    }

    getCustomerData() {
        axios.get(getApiUrl() + '/public/getcustomersdata')
            .then(response => JSON.parse(JSON.stringify(response)))
            .then(customersdata => this.setState({ customersData: customersdata.data.customersData }))
            .catch(err => console.error('Error ', err.toString()));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const message = this.state.showSuccess;

        const options = this.state.customersData.map(customers => ({
            value: customers.Koncern,
            label: customers.Koncern,
            id: customers.Koncern_id,
            Kundansvarig: customers.Kundansvarig
        }));

        return (
            <div className="app-content block">
                <div className="block">
                    <div className="columns content-head">
                        <div className="column">
                            <h2 className="title is-large">
                                Skapa nytt dotterbolag
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="block"></div>
                <form onSubmit={this.addCustomer}>
                    <div className="columns">
                        <div className="column is-3">
                            <h6 className="title is-6">Bolagsinformation</h6>
                        </div>
                        <div className="column">
                            <label className="label">Dotterbolag
                                <div className="field">
                                    <div className="control">
                                        <Select
                                            placeholder="Koncern"
                                            value={this.state.selectedValueCustomer}
                                            onChange={this.handleCustomerChange}
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </label>

                        </div>
                        <div className="column is-6"></div>
                    </div>
                    <div className="columns">
                    <div className="column is-3"></div>
                        <div className="column">
                            <label className="label">Dotterbolag
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="customerDotterbolag" onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>
                            </div>
                            <div className="column">
                            <label className="label">Organisationsnummer
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="customerDotterbolagOrganisationsnummer" required onChange={this.onChange} /><br />
                                    </div>
                                </div>
                            </label>

                        </div>
                        <div className="column is-3"></div>
                    </div>

                    <div className="block"><hr /></div>

                    <div className="block">
                        {message && (
                            <article className="message is-success">
                                <div className="message-body">
                                    Dotterbolaget har skapats!
                                    </div>
                            </article>
                        )}
                        <button className="button is-primary is-outlined left">
                            <Link
                                to={{
                                    pathname: `/dotterbolag`,
                                }}>
                                <FontAwesomeIcon icon="caret-left" /> Tillbaka till Dotterbolag
                            </Link>
                        </button>
                        <button className="button is-primary is-outlined right" type="submit"><FontAwesomeIcon icon="save" /> Spara kund</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default AddSubCustomer;