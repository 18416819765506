// Lägg till användare
import React, { Component } from 'react';
import Link from "react-router-dom/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class SubCustomer extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
            activeDotterbolag: []
        };
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;

        axios.get(getApiUrl() + '/public/getDotterbolag?dotterbolagid=' + params.id)
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(dotterbolag => this.setState({ activeDotterbolag: dotterbolag.data.dotterbolagData }))
        .catch(err => console.error('Error ', err.toString()));
    }

    render() {
        const dotterbolag = this.state.activeDotterbolag;
        return (
            <div className="app-content block">
                {this.state.activeDotterbolag.length !== 0 &&

                    <div className="block">
                        <div className="columns content-head">
                            <div className="column">
                                <h2 className="title is-large">
                                    {dotterbolag.Koncern}
                                </h2>
                            </div>
                        </div>
                        <div className="block">
                            <div className="columns">
                                <div className="column is-3">
                                    <h6 className="title is-6">Bolagsinformation</h6>
                                </div>
                                <div className="column is-3">
                                    <p><strong>Dotterbolag:</strong> <br /><span>{dotterbolag.Dotterbolag}</span></p>
                                </div>
                                <div className="column is-3">
                                    <p><strong>Organisationsnummer:</strong> <br /><span>{dotterbolag.Organisationsnummer_Dotterbolag}</span></p>
                                </div>
                                <div className="column is-3">
                                    <p><strong>Kundansvarig:</strong> <br /><span>{dotterbolag.Kundansvarig}</span></p>
                                </div>
                            </div>

                            <div className="block"><hr /></div>

                            <div className="block">
                                <button className="button is-primary is-outlined left">
                                    <Link
                                        to={{
                                            pathname: `/dotterbolag`,
                                        }}>
                                        <FontAwesomeIcon icon="caret-left" /> Tillbaka till Dotterbolag
                                    </Link>
                                </button>
                                <button className="button is-primary is-outlined right">
                                    <Link
                                        to={{
                                            pathname: `/redigera-dotterbolag/${dotterbolag.Dotterbolag_id}`,
                                        }}>
                                        <FontAwesomeIcon icon="pen" /> Uppdatera dotterbolag
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default SubCustomer;