// Lägg till användare
import React, { Component } from 'react';
import Link from "react-router-dom/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class Customer extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
            activeCustomer: []
        };
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;

        axios.get(getApiUrl() + '/public/getCustomer?customerid=' + params.id)
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(customers => this.setState({ activeCustomer: customers.data.customerData }))
        .catch(err => console.error('Error ', err.toString()));
    }

    render() {
        const customer = this.state.activeCustomer;
        return (
            <div className="app-content block">
                {this.state.activeCustomer.length !== 0 &&

                    <div className="block">
                        <div className="columns content-head">
                            <div className="column">
                                <h2 className="title is-large">
                                    {customer.Koncern}
                                </h2>
                            </div>
                        </div>
                        <div className="block">
                            <div className="columns">
                                <div className="column is-3">
                                    <h6 className="title is-6">Bolagsinformation</h6>
                                </div>
                                <div className="column is-3">
                                    <p><strong>Koncern:</strong> <br /><span>{customer.Koncern}</span></p>
                                </div>
                                <div className="column is-3">
                                    <p><strong>Organisationsnummer:</strong> <br /><span>{customer.Organisationsnummer}</span></p>
                                </div>
                                <div className="column is-3">
                                    <p><strong>Kundansvarig:</strong> <br /><span>{customer.Kundansvarig}</span></p>
                                </div>
                            </div>

                            <div className="block"><hr /></div>

                            <div className="block">
                                <button className="button is-primary is-outlined left">
                                    <Link
                                        to={{
                                            pathname: `/forsakringar`,
                                        }}>
                                        <FontAwesomeIcon icon="caret-left" /> Tillbaka till Försäkringar
                                    </Link>
                                </button>
                                <button className="button is-primary is-outlined right">
                                    <Link
                                        to={{
                                            pathname: `/redigera-kund/${customer.id}`,
                                        }}>
                                        <FontAwesomeIcon icon="pen" /> Uppdatera försäkring
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Customer;