// Lägg till användare
import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import Link from "react-router-dom/Link";
import Select from 'react-select';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class EditSubCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customersData: [],
            activedotterbolag: [],
            customerKoncernId: '',
            inputValue: '',
            dotterbolagOrgNr: '',
            dotterbolagName: '',
            dotterbolagKundansvarig: '',
            showSuccess: false
        };

        this.updatedotterbolag = this.updatedotterbolag.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    handleCustomerChange(e) {
        this.setState({
            customerKoncernId: e.id
        });
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;

        axios.get(getApiUrl() + '/public/getDotterbolag?dotterbolagid=' + params.id)
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(dotterbolag => this.setState({ activedotterbolag: dotterbolag.data.dotterbolagData }))
        .catch(err => console.error('Error ', err.toString()));

        this.getCustomerData();
    }

    getCustomerData() {
        axios.get(getApiUrl() + '/public/getcustomersdata')
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(customersdata => this.setState({ customersData: customersdata.data.customersData }))
        .catch(err => console.error('Error ', err.toString()));
    }

    // scrollToMyRef = () => {
    //     window.scrollTo({
    //         top: this.myRef.offsetTop,
    //     });
    // };

    updatedotterbolag(event) {
        event.preventDefault();
        let dotterbolagOrgNr = !this.state.dotterbolagOrgNr ? this.state.activedotterbolag.Organisationsnummer_Dotterbolag : this.state.dotterbolagOrgNr;
        let dotterbolagName = !this.state.dotterbolagName ? this.state.activedotterbolag.Dotterbolag : this.state.dotterbolagName;
        let customerKoncernId = !this.state.customerKoncernId ? this.state.activedotterbolag.Koncern_id : this.state.customerKoncernId;

        var self = this;

        axios.post(getApiUrl() + '/public/editSubCustomer', {
            Organisationsnummer_Dotterbolag: dotterbolagOrgNr,
            Dotterbolag: dotterbolagName,
            Koncern_id: customerKoncernId,
            id_db: this.state.activedotterbolag.id_db
        })
            .then(function () {
                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onChange(e) {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        this.setState({
            [e.target.name]: value
        });
    }

    render() {
        const dotterbolag = this.state.activedotterbolag;
        const message = this.state.showSuccess;

        const options = this.state.customersData.map(customers => ({
            value: customers.Koncern,
            label: customers.Koncern,
            id: customers.Koncern_id,
            Kundansvarig: customers.Kundansvarig
        }));
        
        /*
        const activeKoncern = options.find(function(element) {
            //return element.id === dotterbolag.Koncern_id;
        });
        */
        
        return (

            <div className="app-content block">
                {this.state.activedotterbolag.length !== 0 &&

                    <div className="block">
                        <div className="columns content-head">
                            <div className="column">
                                <h2 className="title is-large">
                                    {dotterbolag.Dotterbolag}
                                </h2>
                            </div>
                        </div>

                        <div className="block"></div>
                        <form>
                            <div className="columns">
                                <div className="column is-3">
                                    <h6 className="title is-6">Bolagsinformation</h6>
                                </div>
                                <div className="column is-3">
                                    <label className="label">Koncern
                                        <div className="field">
                                            <div className="control">
                                                <Select
                                                    placeholder="Koncern"
                                                    value={this.state.selectedValueCustomer}
                                                    onChange={this.handleCustomerChange}
                                                    options={options}
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3"></div>
                                <div className="column is-3"></div>

                            </div>
                            <div className="columns">
                                <div className="column is-3"></div>
                                <div className="column is-3">

                                    <label className="label">Dotterbolag
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="dotterbolagName" defaultValue={dotterbolag.Dotterbolag} onChange={this.onChange} /><br />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3">
                                    <label className="label">Organisationsnummer
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="dotterbolagOrgNr" defaultValue={dotterbolag.Organisationsnummer_Dotterbolag} onChange={this.onChange} /><br />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="column is-3">
                                    {/* <label className="label">Kundansvarig
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="dotterbolagKundansvarig" defaultValue={dotterbolag.Kundansvarig} onChange={this.onChange} /><br />
                                            </div>
                                        </div>
                                    </label> */}
                                </div>
                            </div>

                            <div className="block"><hr /></div>
                            <div className="block">
                                {message && (
                                    <article className="message is-success">
                                        <div className="message-body">
                                            Dotterbolaget har uppdaterats!
                                    </div>
                                    </article>
                                )}
                                <button className="button is-primary is-outlined left">
                                    <Link
                                        to={{
                                            pathname: `/dotterbolag`
                                        }}>
                                        <FontAwesomeIcon icon="caret-left" /> Tillbaka till Dotterbolag
                                    </Link>
                                </button>
                                <button className="button is-primary is-outlined right" onClick={this.updatedotterbolag}>
                                    <FontAwesomeIcon icon="save" /> Spara Dotterbolag
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        );
    }
}

export default EditSubCustomer;