// Lägg till användare
import React, { Component } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

const options = [
    { value: 'none', label: 'Välj' },
    { value: 'Administratör', label: 'Administratör' },
    { value: 'Användare', label: 'Användare' }
];

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            activeUser: [],
            inputValue: '',
            userPreName: '',
            userLastName: '',
            userUserName: '',
            userPassword: '',
            userPasswordVerify: '',
            userEmail: '',
            showSuccess: false,
            selectValue: null,
            selectedOption: null,
        };

        this.updateUser = this.updateUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        axios.get(getApiUrl() + '/public/singleUser?userid=' + params.id)
            .then(response => JSON.parse(JSON.stringify(response)))
            .then(users => this.setState({ activeUser: users.data.userData }))
            .catch(err => console.error('Error ', err.toString()));
    }

    updateUser(event) {
        event.preventDefault();
        let userPreName = !this.state.userPreName ? this.state.activeUser.firstname : this.state.userPreName;
        let userLastName = !this.state.userLastName ? this.state.activeUser.lastname : this.state.userLastName;
        let userUserName = !this.state.userUserName ? this.state.activeUser.username : this.state.userUserName;
        let userPassword = !this.state.userPassword ? this.state.activeUser.password : this.state.userPassword;
        let userPasswordVerify = !this.state.userPasswordVerify ? this.state.activeUser.password : this.state.userPasswordVerify;
        let userEmail = !this.state.userEmail ? this.state.activeUser.email : this.state.userEmail;
        let userRole = !this.state.selectValue ? this.state.activeUser.role : this.state.selectValue;

        var self = this;

        if (userPassword !== userPasswordVerify) {
            console.log("Error: passwords do not match");
            this.setState({
                showPasswordError: true
            }, () => {
                setInterval(function () {
                    self.setState({
                        showPasswordError: false
                    });
                }, 3000);
            });
            return false;
        }
        axios.post(getApiUrl() + '/public/editUser', {
            firstname: userPreName,
            lastname: userLastName,
            username: userUserName,
            password: userPassword,
            email: userEmail,
            role: userRole,
            userid: this.state.activeUser.id
        })
            .then(function () {
                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    removeUser(event) {
        event.preventDefault();
        var self = this;
        axios.post(getApiUrl() + '/public/removeUser', {
            userid: this.state.activeUser.id
        })
            .then(function () {
                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })

            .catch(function (error) {
                console.log(error);
            });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChange(selectedOption) {
        this.setState({
            selectValue: selectedOption.value
        }, () => {
            // this.updateFilteredList();
        });
    }

    // updateInputValue(e) {
    //     this.setState({
    //         inputValue: e.target.value
    //     });
    // }

    render() {

        const user = this.state.activeUser;
        const message = this.state.showSuccess;
        const passwordErrorMessage = this.state.showPasswordError;

        return (
            <div className="app-content block">
                <div className="block">
                    <div className="columns content-head">
                        <div className="column">
                            <h2 className="title is-large">
                                Redigera användare
                            </h2>
                        </div>
                    </div>
                    <div className="block"></div>
                    <form>
                        <div className="columns">
                            <div className="column">
                                <label className="label">Förnamn
                                <div className="field">
                                        <div className="control">
                                            <input className="input" type="text" name="userPreName" required defaultValue={user.firstname} onChange={this.onChange} /><br />
                                        </div></div></label>
                                <label className="label">Efternamn
                                <div className="field">
                                        <div className="control">
                                            <input className="input" type="text" name="userLastName" required defaultValue={user.lastname} onChange={this.onChange} /><br />
                                        </div></div></label>
                                <label className="label">E-post
                                <div className="field">
                                        <div className="control">
                                            <input className="input" type="text" name="userEmail" required defaultValue={user.email} onChange={this.onChange} /><br />
                                        </div></div></label>
                            </div>
                            <div className="column">
                                <label className="label">Användarnamn
                                <div className="field">
                                        <div className="control">
                                            <input className="input" type="text" name="userUserName" required minlength="6" defaultValue={user.username} onChange={this.onChange} /><br />
                                        </div></div></label>
                                <label className="label">Lösenord
                                <div className="field">
                                        <div className="control">
                                            <input className="input" type="password" name="userPassword" required onChange={this.onChange} /><br />
                                        </div></div></label>
                                <label className="label">Verifiera lösenord
                                <div className="field">
                                        <div className="control">
                                            <input className="input" type="password" name="userPasswordVerify" required onChange={this.onChange} /><br />
                                        </div></div></label>
                                <span className="select-field">
                                    <label className="label">Roll
                                <Select
                                            value={this.state.selectedValue}
                                            onChange={this.handleChange}
                                            options={options}
                                        />
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className="block">
                            {message && (
                                <article className="message is-success">
                                    <div className="message-body">
                                        Användaren har blivit uppdaterad!
                                    </div>
                                </article>
                            )}

                            {passwordErrorMessage && (
                                <article className="message is-danger">
                                    <div className="message-body">
                                        Lösenorden matchar inte.
                                    </div>
                                </article>
                            )}
                            <button className="button is-primary is-outlined" onClick={this.removeUser}>
                                <FontAwesomeIcon icon="trash" /> Ta bort användare
                            </button>
                            <button className="button is-primary is-outlined right" onClick={this.updateUser}>
                                <FontAwesomeIcon icon="save" /> Spara
                                </button>
                            <br />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default EditUser;