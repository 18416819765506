// Lägg till användare
import React, { Component } from 'react';
import Link from "react-router-dom/Link";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

const options = [
    { value: 'none', label: 'Välj' },
    { value: 'Administratör', label: 'Administratör' },
    { value: 'Användare', label: 'Användare' }
];

class AddUser extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            userPreName: '',
            userLastName: '',
            userUserName: '',
            userPassword: '',
            userPasswordVerify: '',
            userEmail: '',
            selectValue: null,
            selectedOption: null,
        };

        this.addUser = this.addUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    addUser(event) {
        event.preventDefault();

        let userPassword = this.state.userPassword;
        let userPasswordVerify = this.state.userPasswordVerify;

        var self = this;

        if (userPassword !== userPasswordVerify) {
            console.log("Error: passwords do not match");
            this.setState({
                showPasswordError: true
            }, () => {
                setInterval(function () {
                    self.setState({
                        showPasswordError: false
                    });
                }, 3000);
            });
            return false;
        }

        axios.post(getApiUrl() + '/public/addUser', {
            firstname: this.state.userPreName,
            lastname: this.state.userLastName,
            username: this.state.userUserName,
            password: this.state.userPassword,
            email: this.state.userEmail,
            role: this.state.selectValue
        })
            .then(function () {
                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChange(selectedOption) {
        this.setState({
            selectValue: selectedOption.value
        }, () => {
            // this.updateFilteredList();
        });
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const message = this.state.showSuccess;
        const passwordErrorMessage = this.state.showPasswordError;

        return (
            <div className="app-content block">
                <div className="block">
                    <div className="columns content-head">
                        <div className="column">
                            <h2 className="title is-large">
                                Lägg till användare
                            </h2>
                        </div>
                    </div>
                    <div className="block">
                        <form onSubmit={this.addUser}>
                            <div className="columns is-half">
                                <div className="column">
                                    <label className="label">Förnamn
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="userPreName" required onChange={this.onChange} /><br />
                                            </div>
                                        </div>
                                    </label>
                                    <label className="label">Efternamn
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="userLastName" required onChange={this.onChange} /><br />
                                            </div>
                                        </div>
                                    </label>

                                    <label className="label">E-post
                                    <input className="input" type="email" name="userEmail" required onChange={this.onChange} /><br />
                                    </label>
                                </div>
                                <div className="column is-half">
                                    <label className="label">Användarnamn
                                        <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="userUserName" required minlength="6" onChange={this.onChange} /><br />
                                            </div>
                                        </div>
                                    </label>
                                    <label className="label">Lösenord
                                        <input className="input" type="password" name="userPassword" required onChange={this.onChange} /><br />
                                    </label>
                                    <label className="label">Verifiera lösenord
                                        <input className="input" type="password" name="userPasswordVerify" required onChange={this.onChange} /><br />
                                    </label>
                                    <span className="select-field">
                                        <Select
                                            required
                                            value={this.state.selectedValue}
                                            onChange={this.handleChange}
                                            options={options}
                                            placeholder="Roll"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="block"><hr /></div>

                            <div className="block">
                                {message && (
                                    <article className="message is-success">
                                        <div className="message-body">
                                            Användaren har blivit skapad!
                                    </div>
                                    </article>
                                )}
                                {passwordErrorMessage && (
                                    <article className="message is-danger">
                                        <div className="message-body">
                                            Lösenorden matchar inte.
                                    </div>
                                    </article>
                                )}
                                <button className="button is-primary is-outlined left">
                                    <Link
                                        to={{
                                            pathname: `/anvandare`,
                                        }}>
                                        <FontAwesomeIcon icon="caret-left" /> Tillbaka till Användare
                                    </Link>
                                </button>
                                <button className="button is-primary is-outlined right" type="submit">Lägg till användare</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddUser;