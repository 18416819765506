// Lägg till användare
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import Link from "react-router-dom/Link";
// import { gql, graphql } from 'react-apollo';
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

let headers = new Headers();
headers.append("Access-Control-Allow-Origin", "*");

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userUsername: '',
            userPassword: '',
            redirectToReferrer: false,
            showErrorMessage: false
        };

        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    login(event) {
        var self = this;
        event.preventDefault();
        if (this.state.userUsername && this.state.userPassword) {
            axios.post(getApiUrl() + '/public/login', {
                username: this.state.userUsername,
                password: this.state.userPassword,
            })
                .then(function (response) {
                    let responseJson = response.data;
                    if (responseJson.response === 'success') {
                        // Do stuff below else show error
                        sessionStorage.setItem('userData', JSON.stringify(responseJson));
                        self.setState({
                            redirectToReferrer: true
                        });
                    }
                    else {
                        self.setState({
                            showErrorMessage: true
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    // onSubmit = async () => {
    //     const response = await this.props.mutate({
    //         variables: this.state
    //     });

    //     console.log(response);
    // }

    render() {
        const message = this.state.showErrorMessage;

        if (this.state.redirectToReferrer || sessionStorage.getItem('userData')) {
            return (<Redirect to={'/hem'} />)
        }
        return (
            <div id="login" className="container">
                <div className="login-card">
                    <div className="card-title">
                        <h1>Logga in</h1>
                    </div>

                    <div className="content">
                        {message && (
                            <article className="message is-danger">
                                <div className="message-body">
                                    Användarnamnet och lösenordet stämmer inte överens.
                            </div>
                            </article>
                        )}

                        <form onSubmit={this.login}>
                            <div className="field">
                                <div className="control">
                                    <input id="user" className="input" type="text" name="userUsername" title="Användare" placeholder="Användare" required autoFocus onChange={e => this.onChange(e)} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input id="password" className="input" type="password" name="userPassword" title="Lösenord" placeholder="Lösenord" required onChange={e => this.onChange(e)} />
                                </div>
                            </div>

                            {/* <div className="level options">
                                <div className="checkbox level-left">
                                    <input type="checkbox" id="checkbox" className="regular-checkbox"/>
                                    <label for="checkbox"></label>
                                    <span>Remember me</span>
                                </div>

                                <a className="btn btn-link level-right" href="#">Forgot Password?</a>
                            </div> */}

                            <button className="button is-primary is-outlined" type="submit">Logga in</button>
                        </form>
                    </div>
                </div>
            </div>


        );
    }
}

export default Login;