// Lägg till användare
import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "react-router-dom/Link";
import Select from 'react-select';

import axios from 'axios';
import { getApiUrl } from '../config/Axios';
class Kunder extends Component {

    state = {
        customersRepsonsibility: [],
        customers: [],
        customer: [],
        searchValue: '',
        toggledComp: false,
        selectValue: null,
        selectedOption: null,
        items: []
    }

    handleSearch = this.handleSearch.bind(this);
    toggleFilter = this.toggleFilter.bind(this);
    updateFilteredList = this.updateFilteredList.bind(this);
    handleChange = this.handleChange.bind(this);

    /**
    * Handels input form search field
    * @param  {[string]} e [event handler]
    */
    handleSearch(e) {
        e.preventDefault();
        this.setState({
            searchValue: e.target.value
        }, () => {
            this.updateFilteredList();
        });
    }

    updateFilteredList() {
        let self = this;
        let updatedList = this.state.customers;

        if (self.state.selectValue !== null) {
            if (self.state.selectValue === 'none') return self.setState({ items: self.state.customers });
            updatedList = updatedList.filter(function (item) {
                return item.Kundansvarig === self.state.selectValue;
            });
        }

        updatedList = updatedList.filter(function (item) {
            return item.Koncern.toLowerCase().indexOf(self.state.searchValue.toLowerCase()) !== -1;
        });

        this.setState({ items: updatedList });
    }

    handleChange(selectedOption) {
        this.setState({
            selectValue: selectedOption.value
        }, () => {
            this.updateFilteredList();
        });
    }

    toggleFilter(filterBox) {
        this.setState(prevState => ({
            toggledComp: !prevState.toggledComp
        }));
    }

    getCustomersRepsonsibility() {
        axios.get(getApiUrl() + '/public/getcustomersrepsonsibility')
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(customersrepsonsibility => this.setState({ customersRepsonsibility: customersrepsonsibility.data.customersRepsonsibility }))
        // .then(console.log('test: ' + this.state.customersRepsonsibility))
        .catch(err => console.error('Error ', err.toString()));
    }

    getCustomers = () => {
        axios.get(getApiUrl() + '/public/customers')
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(customers => this.setState({ customers: customers.data.customers }))
        .then(() => this.setState({ items: this.state.customers }))
        .catch(err => console.error('Error ', err.toString()));
    }

    componentDidMount = () => {
        this.getCustomers();
        this.getCustomersRepsonsibility();
    }

    componentDidUpdate = () => {
        const customers = JSON.stringify(this.state.customers);
        localStorage.setItem("customers", customers);
    }

    render() {
        const uniqueTags = [];
        const options_kundansvarig = this.state.customersRepsonsibility.map(customers => ({
            value: customers.Kundansvarig,
            label: customers.Kundansvarig
        }));

        let list = this.state.items.map((customer, index) => {
            if (uniqueTags.indexOf(customer.Koncern) === -1) {
                return (
                    <div className="card customer-card"
                        key={index}>
                        
                        <div className="card-header">
                            <h4 className="card-title title is-5">
                                <Link to={{ pathname: `/kund/${customer.Koncern_id}`, state: { customer: customer.Koncern } }}>{customer.Koncern}</Link>
                                <span className="right">
                                    <Link
                                        to={{
                                            pathname: `/redigera-kund/${customer.Koncern_id}`,
                                        }}
                                        className="button is-white is-outlined is-small">
                                        <FontAwesomeIcon icon="pen" /> Redigera
                                    </Link>
                                </span>
                            </h4>
                        </div>
                        <div className="card-content">
                            <div className="columns">
                                <div className="column">
                                    <p><strong>Organisationsnummer:</strong> <span>{customer.Organisationsnummer}</span></p>
                                </div>
                                <div className="column">
                                    <p><strong>Kundansvarig:</strong> <span>{customer.Kundansvarig}</span></p>
                                    {/* <p><strong>Dotterbolag:</strong> <span>{customer.Dotterbolag}</span></p>
                                    <p><strong>Dotterbolagets organisationsnummer:</strong> <span>{customer.Organisationsnummer_Dotterbolag}</span></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });

        return (
            <div className="app-content block">
                <div className="columns content-head">
                    <div className="column">
                        <h2 className="title is-large">Alla Kunder</h2>
                    </div>
                    <div className="column">
                        <button className="right button is-primary is-outlined">
                            <Link
                                to={{
                                    pathname: `/ny-kund`
                                }}>
                                <FontAwesomeIcon icon="plus" /> Lägg till kund
                            </Link>
                        </button>
                    </div>
                </div>
                <div className="block box content-filter">
                    <div className="columns">
                        <div className="column">
                            <div className="field">
                                <div className="control">
                                    <form>
                                        <input className="search input" type="text" name="insuranceName" placeholder="Sök.." onChange={this.handleSearch} />
                                    </form>
                                </div>
                            </div>
                        </div>

                        {/* <div className="column">
                            <div className="field">
                                <div className="control">
                                    <div className="b-checkbox is-primary">
                                        <input id="checkbox" className="styled" type="checkbox" onClick={this.toggleFilter} />
                                        <label for="checkbox">
                                            Visa inte dotterbolag
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="column">
                            <div className="field">
                                <div className="control">
                                    <div className="b-checkbox is-primary">
                                        <input id="checkbox" className="styled" type="checkbox" value={this.state.toggleExpiredState} onChange={this.toggleExpired} />
                                        <label for="checkbox">
                                            Dölj utgågna avtal
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="column">
                            <div className="field">
                                <div className="control">
                                    <div className="b-checkbox is-primary">
                                        <input id="checkbox" className="styled" checked type="checkbox" onClick={this.toggleFilter} />
                                        <label for="checkbox">
                                            Visa Utgågna avtal
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="column">
                            <div className="field">
                                <div className="control">
                                    <div className="b-checkbox is-primary">
                                        <input id="checkbox" className="styled" type="checkbox" onClick={this.toggleDate} />
                                        <label for="checkbox">
                                            Datumordning
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="column is-one-third">
                            <div className="field">
                                <div className="control">
                                    <span className="select-responsible">

                                        <Select
                                            value={this.state.selectedValue}
                                            onChange={this.handleChange}
                                            options={options_kundansvarig}
                                        />

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block content-body">{list}</div>
            </div>
        );
    }

}

export default Kunder;