// Lägg till användare
import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import Link from "react-router-dom/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { getApiUrl } from '../config/Axios';

class EditCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeCustomer: [],
            inputValue: '',
            customerOrgNr: '',
            customerId: '',
            customerName: '',
            customerKundansvarig: '',
            showSuccess: false
        };

        this.updateCustomer = this.updateCustomer.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    componentDidMount = () => {
        const { match: { params } } = this.props;

        axios.get(getApiUrl() + '/public/getCustomer?customerid=' + params.id)
        .then(response => JSON.parse(JSON.stringify(response)))
        .then(customers => this.setState({ activeCustomer: customers.data.customerData }))
        .catch(err => console.error('Error ', err.toString()));
    }

    scrollToMyRef = () => {
        window.scrollTo({
            top: this.myRef.offsetTop,
        });
    };

    updateCustomer(event) {
        event.preventDefault();
        let customerOrgNr = !this.state.customerOrgNr ? this.state.activeCustomer.Organisationsnummer : this.state.customerOrgNr;
        let customerKoncernId = !this.state.customerKoncernId ? this.state.activeCustomer.Koncern_id : this.state.customerKoncernId;
        let customerName = !this.state.customerName ? this.state.activeCustomer.Koncern : this.state.customerName;
        let customerKundansvarig = !this.state.customerKundansvarig ? this.state.activeCustomer.Kundansvarig : this.state.customerKundansvarig;
        
        var self = this;
        
        axios.post(getApiUrl() + '/public/editCustomer', {
            Organisationsnummer: customerOrgNr,
            Koncern_id: customerKoncernId,
            Koncern: customerName,
            Kundansvarig: customerKundansvarig,
            id_cu: this.state.activeCustomer.id_cu
        })
            .then(function () {
                self.setState({
                    showSuccess: true
                }, () => {
                    setInterval(function () {
                        self.setState({
                            showSuccess: false
                        });
                    }, 3000);
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onChange(e) {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        this.setState({ 
            [e.target.name]: value
         });
    }

    render() {
        const customer = this.state.activeCustomer;
        const message = this.state.showSuccess;
        
        return (

            <div className="app-content block">
                {this.state.activeCustomer.length !== 0 &&

                    <div className="block">
                        <div className="columns content-head">
                            <div className="column">
                                <h2 className="title is-large">
                                    {customer.Koncern}
                                </h2>
                            </div>
                        </div>

                        <div className="block"></div>
                        <form>
                            <div className="columns">
                                <div className="column">
                                    <h6 className="title is-6">Bolagsinformation</h6>
                                </div>
                                <div className="column">
                                    <label className="label">Koncern
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="customerName" defaultValue={customer.Koncern} onChange={this.onChange} /><br />
                                            </div></div></label>
                                    <label className="label">Organisationsnummer
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="customerOrgNr" defaultValue={customer.Organisationsnummer} onChange={this.onChange} /><br />
                                            </div></div></label>
                                </div>
                                <div className="column">
                                <label className="label">Kundansvarig
                                    <div className="field">
                                            <div className="control">
                                                <input className="input" type="text" name="customerKundansvarig" defaultValue={customer.Kundansvarig} onChange={this.onChange} /><br />
                                            </div></div></label>
                                </div>
                                <div className="column is-3">
                                </div>
                            </div>

                            <div className="block"><hr /></div>
                            <div className="block">
                                {message && (
                                    <article className="message is-success">
                                        <div className="message-body">
                                            Kunden har uppdaterats!
                                    </div>
                                    </article>
                                )}
                                <button className="button is-primary is-outlined left">
                                    <Link
                                        to={{
                                            pathname: `/kunder`
                                        }}>
                                        <FontAwesomeIcon icon="caret-left" /> Tillbaka till Kunder
                                    </Link>
                                </button>
                                <button className="button is-primary is-outlined right" onClick={this.updateCustomer}>
                                    <FontAwesomeIcon icon="save" /> Spara Kund
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        );
    }
}

export default EditCustomer;